import React from "react";

import Slider from "react-slick";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

import "../../styles/hero-slider.css";

const HeroSlider = () => {
  const settings = {
    fade: true,
    speed: 2000,
    autoplaySpeed: 3000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  return (
    <Slider {...settings} className="hero__slider">
      <div className="slider__item slider__item-01 mt0">
        <Container>
          <div className="slider__content ">
          <h4 className="text-light mb-3">
           </h4>
            <h1 className="text-light mb-4">Sagaci Syndicat des Agents Généraux d'Assurance de Côte d'Ivoire  </h1>
            <button className="btn reserve__btn mt-4">
              <Link to="/contact">Rejoignez-nous</Link>
            </button>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-02 mt0">
        <Container>
          <div className="slider__content ">
          <h4 className="text-light mb-3">
           </h4>
            <h1 className="text-light mb-4"> Défendre et de préserver les intérêts des agents
généraux en assurance  </h1>

            <button className="btn reserve__btn mt-4">
              <Link to="/contact">Rejoignez-nous</Link>
            </button>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-03 mt0">
        <Container>
          <div className="slider__content ">
            <h4 className="text-light mb-3">
           </h4>
            <h1 className="text-light mb-4">Promouvoir les facilitations des pénétrations des assurances en Côte d'Ivoire</h1>

            <button className="btn reserve__btn mt-4">
              <Link to="/contact">Rejoignez-nous</Link>
            </button>
          </div>
        </Container>
      </div>
      <div className="slider__item slider__item-04 mt0">
              <Container>
                <div className="slider__content ">
                  <h4 className="text-light mb-3">
                 </h4>
                  <h1 className="text-light mb-4">Promouvoir les facilitations des pénétrations des assurances en Côte d'Ivoire</h1>

                  <button className="btn reserve__btn mt-4">
                    <Link to="/contact">Rejoignez-nous</Link>
                  </button>
                </div>
              </Container>
            </div>
    </Slider>
  );
};

export default HeroSlider;
